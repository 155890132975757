import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { post } from "aws-amplify/api";
import { loadStripe } from "@stripe/stripe-js";
import { FaStar, FaCheck, FaLock } from "react-icons/fa";

const features = [
  "Unlimited remodeling generation",
  "Unlimited furnishing customization",
  "Detailed cost estimation",
  "Matching with experts",
  "Premium Support",
];

const PricingSection = ({ userAttributes }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const isLive = true;

  const LIVE_PRODUCT_ID = "price_1Q3X1fLWORAlS27wE0M3HBIE";
  const TEST_PRODUCT_ID = "price_1Q3VV1LWORAlS27wgbJDEyfD";
  const LIVE_PUBLISHABLE_KEY =
    "pk_live_51PWP18LWORAlS27wAaeQBosDkc5D40YW4eaxAxEgcjlikVpzTIKQc4Il8aO6j3JBPw4ryuVkz5c8IcIEdo8m7O0800m5s8CDVn";
  const TEST_PUBLISHABLE_KEY =
    "pk_test_51PWP18LWORAlS27wrhKgVpyxFJOnCJAwnADfFDcKRHGhBhvbM3o2THwThpp0jUTmj4kZJ8jQXxaTNILzfwNQi0Oz00eZLjVVXL";

  const productId = isLive ? LIVE_PRODUCT_ID : TEST_PRODUCT_ID;
  const publishableKey = isLive ? LIVE_PUBLISHABLE_KEY : TEST_PUBLISHABLE_KEY;
  // const productId = "price_1Q3idALWORAlS27wx9U7YwbV"; // $200
  // const productId = "price_1Q3VV1LWORAlS27wgbJDEyfD"; // $100

  const payMode = "payment"; // payment, subscription

  const handleCheckout = async () => {
    if (!userAttributes) {
      navigate("/login");
      return;
    }

    if (productId === null) {
      window.open("mailto:seyeong@resia.io", "_blank");
      return;
    }

    setIsLoading(true);

    try {
      const restOperation = post({
        apiName: "payment",
        path: "/stripe/checkout",
        options: {
          body: {
            productId,
            quantity: 1,
            customerEmail: userAttributes.email,
            payMode,
          },
          headers: {
            "Content-Type": "application/json",
          },
        },
      });
      const { body } = await restOperation.response;
      const responseBody = await body.json();
      const sessionId = responseBody.sessionId;

      const stripe = await loadStripe(publishableKey);
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error("Error during redirect to checkout: ", error);
      }
    } catch (error) {
      console.error("Error during subscription: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="py-10 bg-gray-800 sm:py-16 lg:py-10 rounded-xl mt-20 mb-20">
      <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8 rounded-xl">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 lg:gap-x-20 gap-y-10">
          <div className="flex flex-col justify-between lg:py-5">
            <h2 className="text-3xl font-bold leading-tight text-white sm:text-4xl lg:leading-tight lg:text-5xl">
              Get Complete Remodel Estimates with Resia
            </h2>

            <div className="mt-10">
              <div className="flex items-center space-x-1">
                {Array(5)
                  .fill(0)
                  .map((_, index) => (
                    <FaStar key={index} className="w-6 h-6 text-orange-500" />
                  ))}
              </div>

              <div className="mt-6">
                <p className="text-lg leading-relaxed text-white">
                  At Resia, we streamline the remodeling process with
                  cutting-edge AI tools, providing quick estimates,
                  comprehensive cost breakdowns, and a seamless project
                  management experience for homeowners and contractors alike.
                </p>
              </div>

              <div className="flex items-center mt-8">
                <div className="ml-4">
                  <p className="text-base font-semibold text-white">
                    Empowering Contractors
                  </p>
                  <p className="mt-px text-sm text-gray-400">
                    Your trusted partner in renovation success
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="overflow-hidden bg-white rounded-md">
              <div className="p-10">
                <h3 className="text-xs font-semibold tracking-widest text-purple-600 uppercase">
                  Per Project Plan
                </h3>
                <div className="mt-4">
                  <p className="text-2xl font-semibold text-gray-500 line-through">
                    $599
                  </p>
                  <p className="text-6xl font-bold text-black">$299</p>
                  <p className="mt-2 text-sm text-gray-500">
                    for the 100 generation credits
                  </p>
                </div>

                <ul className="flex flex-col mt-8 space-y-4">
                  {features.map((feature, index) => (
                    <li
                      key={index}
                      className="inline-flex items-center space-x-2"
                    >
                      <FaCheck className="w-5 h-5 text-green-500" />
                      <span className="text-base font-medium text-gray-900">
                        {feature}
                      </span>
                    </li>
                  ))}
                </ul>

                <Link
                  className="inline-flex items-center justify-center w-full px-8 py-4 mt-10 font-semibold text-white transition-all duration-200 rounded-full bg-gradient-to-r from-fuchsia-600 to-blue-600 hover:opacity-80 focus:opacity-80"
                  onClick={handleCheckout}
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                      ></path>
                    </svg>
                  ) : (
                    "Start Your Project"
                  )}
                </Link>

                <div className="flex items-center mt-5">
                  <FaLock className="w-5 h-5 text-gray-500" />
                  <span className="ml-2 text-sm text-gray-500">
                    30 Days Moneyback Guarantee
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
